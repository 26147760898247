import React from 'react';
import './assets/styles/main.css';
import  VistaPrincipal from './pages/VistaPrincipal.js';

function App() {
  return (
    <div class="bg_cont">
      <VistaPrincipal />
    </div>
  );
}

export default App;
