import React from 'react';
import '../assets/styles/Header.css';

function Header() {
  return (
    <header>
      <div className="header">
        <h1 className="logo">
          <span className="letter-a">A</span>
          <span className="letter-u">U</span>
          <span className="letter-t">T</span>
          <span className="letter-o">O</span>
          <span className="letter-m">M</span>
          <span className="letter-a">A</span>
          <span className="letter-t">T</span>
          <span className="letter-e">E</span>
        </h1>
        <nav>
          <a href="#home">Inicio</a> | <a href="#about">¿Quienes Somos?</a> | <a href="#contact">Contacto</a> | <a href="#services">Servicios</a> | <a href="#set">Herramientas</a>
        </nav>
      </div>
    </header>
  );
}

export default Header;
