"use client";
import React from 'react';
import '../assets/styles/MainContent.css';
import { TextGenerateEffect } from './Ui/AnimatedText'; // Ensure the path is correct

function MainContent() {
  const leftText = `"Innovamos tus procesos industriales con automatización de vanguardia"`;
  const rightText = `Automatizamos y optimizamos líneas de producción para mejorar la eficiencia, reducir costos y modernizar equipos industriales. Descubre nuestras soluciones personalizadas y cómo podemos ayudarte a mejorar tu operación.`;

  return (
    <main id="main-content">
      <div className="row-fluid parent">
        <div className="moduletable hello span6 left-box">
          <div className='boxtext'>
            <h2 className='titleL'>Bienvenidos!</h2>
            <div className='animated-text l'>
              <TextGenerateEffect words={leftText} duration={0.2} filter={true} />
            </div>
          </div>
        </div>
        <div className="moduletable hello span6 right-box">
          <div className='boxTleft'>
            <h2 className='titleR'>Acerca de Nosotros</h2>
            <div className='animated-text r'>
              <TextGenerateEffect words={rightText} duration={0.2} filter={true} />
            </div>
          </div>
        </div>
      </div>
      <svg style={{ visibility: 'hidden', position: 'absolute' }} width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="8" result="blur" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
            <feComposite in="SourceGraphic" in2="goo" operator="atop" />
          </filter>
        </defs>
      </svg>
    </main>
  );
}

export default MainContent;
