import React from 'react';
import '../assets/styles/ContactButtom.css';

function ContactButtom() {
    return (
        <div className="button-container ">
            <button className="custom-button">
                Contáctenos para una consulta gratuita
            </button>
            <div className='hovera'></div>
            <svg style={{ visibility: 'hidden', position: 'absolute' }} width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                    <filter id="butonB">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="4" result="blur" /> {/* Valor reducido */}
                        <feColorMatrix in="blur" mode="matrix" values="1 1 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -10" result="butonB" />
                        <feComposite in="SourceGraphic" in2="butonB" operator="atop" />
                    </filter>
                </defs>
            </svg>

        </div>
    );
}

export default ContactButtom;
