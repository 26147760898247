import React from 'react';

import '../assets/styles/Footer.css';
function Footer() {
  return (
    <footer className="sticky-footer">
      <div className="container">
        <p>&copy; AUTOMATE. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
